<template>
  <div>
    <v-page id="blogs" background-color="#F1EDED">
      <v-container fluid class="px-0">
        <v-row no-gutters v-for="(blog, blogIdx) in blogs" :key="blogIdx">
          <v-col class="pa-0 pt-4">
            <v-card>
              <v-row>
                <v-col cols="12" sm="6" class="pa-0 d-flex align-center justify-center" order="1">
                  <v-row align="center" justify="center">
                    <v-col cols="10" lg="8">
                      <v-row>
                        <v-col>
                          <span class="raleway mr-3">{{ localeDate(blog.created_at).format("LL") }} </span>
                          <span>/</span>
                          <span class="c-primary font-weight-bold ml-3" v-if="blog.category">{{ blog.category.translations[$i18n.locale] }}</span>
                          <span class="c-primary font-weight-bold ml-3" v-else>Algemeen</span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <h3>{{ blog.title }}</h3>
                          <span class="text-left" v-html="blog.excerpt"></span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-btn text color="primary" class="font-weight-bold pa-0" :to="{name: 'BlogSingular', params: {id: blog.id}}">Lees Meer</v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6" class="pa-0" :order="blogIdx % 2 == 0 ? 2 : 0" v-if="$vuetify.breakpoint.smAndUp">
                  <v-img :src="blog.featured_image" :aspect-ratio="16/9"></v-img>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

      </v-container>
    </v-page>
    <Footer></Footer>
  </div>
</template>

<script>
import VPage from "@/components/VPage";
//import blogs from "@/data/blogs";
import Footer from "@/views/Footer";
import axios from "../http";
import {localeDate} from "../util/localeDate";

export default {
  name: "Blog",
  components: { VPage, Footer },
  data: () => ({
    blogs: []
  }),
  methods: {
    localeDate
  },
  async mounted() {
    let resp = await axios.get(`/p/${this.$i18n.locale}/blog?order=created_at`);
    this.blogs = resp.data.data;
  }
}
</script>

<style scoped>

</style>
